<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="loader_area" v-show="sequenceEmailLoader && !messageLoader"><quote-loader/></div>
        <div class="global_setting sequence_wpr" v-show="!sequenceEmailLoader || messageLoader">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="left_bar">
                <div class="acton_header mb-4">
                    <div class="left_section">
                        <div class="section_header">
                            <h2>Sequence</h2>
                        </div>
                    </div>
                    <div class="right_section ml-auto">
                        <ul>
                            <li @click="analytics = true">
                                <img src="@/assets/images/analytics.svg">
                            </li>
                            <li @click="sequenceSettings = true">
                                <img src="@/assets/images/slider2.svg">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="extra_info" v-if="sequenceMessages.filter((sequence) => { return sequence.status == 0; }).length">
                    One or more messages are currently in draft mode and won't be delivered to your recipients
                </div>
                <div class="step_type" v-if="sequenceMessages.length == 0">
                    <button class="add_new" @click="handleAddMessage(0)">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
                <draggable v-model="sequenceMessages" tag="ul" item-key="id" class="sequence_list" :animation="200" @change="handleMessageShort">
                    <template #item="{ element, index }">
                        <li>
                            <ul class="sequence_item pointer">
                                <li>
                                    <div class="step_info timer" :class="{ active: selectedDelayIndex == index, draft: element.status == 0 } " @click="selectMessage(element, index, 'delay')">
                                        <i class="fas fa-clock"></i>
                                        <h4>{{ element.days == 0 && index == 0 ? 'Send Immediately' : `Wait ${element.days} ${element.duration} ${element.days > 1 ? `(s)` : ''}`}}</h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="step_info mail" :class="{ active: selectedMessageIndex == index, draft: element.status == 0 } " @click="selectMessage(element, index, 'content')">
                                        <h4>
                                            <label>{{ index + 1 }}</label>
                                            {{ `This message is an ${element.send_method == 3 || element.send_method == 1 ? 'email' : ''} ${element.send_method == 3 ? '&' : ''} ${element.send_method == 3 || element.send_method == 2 ? 'sms' : ''}` }}
                                        </h4>
                                        <div class="msg_cont">
                                            <div class="mail_box" v-if="element.send_method == 1 || element.send_method == 3">
                                                <div class="msg_desc" :class="{'border-0' : element.subject}">
                                                    <p v-html="element.subject ? element.subject : 'Define Message'"></p>
                                                </div>
                                                <div class="status_area">
                                                    <div class="score_circle" title="Average Message Open Rate">
                                                        <div class="inner_circle">
                                                            <div class="score" style="transform: scale(0.5);">{{ element.open_rate }}<span>%</span></div>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                            <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="100" :stroke-dashoffset="100 - element.open_rate"/>
                                                        </svg>
                                                    </div>
                                                    <label :for="`card_status${index}`" class="switch_option capsule_btn m-0" @click.stop="handleEvent">
                                                        <input type="checkbox" :id="`card_status${index}`" :true-value="1" :false-value="0" v-model="element.status" @change="updateMessageStatus(element)" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="sms_box" v-if="element.send_method == 2 || element.send_method == 3" @click="selectMessage(element, index, 'content')">
                                                <div class="msg_desc" :class="{'border-0' : element.sms}">
                                                    <p v-html="element.sms ? element.sms : 'Define Message'"></p>
                                                </div>
                                                <div class="status_area">
                                                    <label :for="`sms_status${index}`" class="switch_option capsule_btn ml-auto" @click.stop="handleEvent">
                                                        <input type="checkbox" :id="`sms_status${index}`" :true-value="1" :false-value="0" v-model="element.status" @change="updateMessageStatus(element)" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="remove_btn" v-if="companyUserCan('delete', 'sequences')" @click="deleteMessage(index, element.id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                            <div class="step_type">
                                <button class="add_new" @click="handleAddMessage(index + 1)">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </li>
                    </template>
                </draggable>
                <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                <div class="m_footer">
                    <button type="button" class="save_btn" @click="toggleSectionbar">Edit</button>
                </div>
            </div>
            <div class="tabs_content" v-show="selectedMessage.id">
                <button class="close_btn" @click="toggleSectionbar"><i class="fas fa-times"></i></button>
                <div class="message_loader_area" v-if="messageLoader"><quote-loader/></div>
                <Form v-else @submit="handleSubmit" v-slot="{ errors }" ref="sequence-form" class="sequence-form">
                    <div class="sequence_details" v-if="selectedDelayIndex != null">
                        <div class="acton_header mb-4">
                            <div class="left_section w-100">
                                <div class="section_header w-100">
                                    <h2>Delay</h2>
                                    <label class="schedule-label" v-if="selectedMessage.has_schedule" @click="viewSchedule = true">View Schedule</label>
                                </div>
                            </div>
                        </div>
                        <div class="extra_info mb-5">
                            <p v-if="form.days == 0">This message will be sent immediately</p>
                            <p v-if="form.days > 0">This message will be sent {{ form.days }} <a>{{ form.duration }} {{form.days > 1 ? `(s)` : ''}} after {{ selectedDelayIndex == 0 ? 'subscription' : 'previous message' }} around <span class="pointer" @click="sequenceSettings = true">{{ sequence.hour }} {{ sequence.timezone }}</span></a></p>
                        </div>
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Delay</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.delay }">
                                        <Field autocomplete="off" name="delay" type="number" min="0" v-model="form.days" />
                                    </div>
                                    <ErrorMessage name="delay" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">of days, hours, or minutes before this message will be sent.</label>
                                    <div class="field_wpr">
                                        <multiselect
                                            v-model="form.duration"
                                            :options="durations"
                                            valueProp="value"
                                            label="label"
                                            :searchable="true"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sequence_details" v-if="selectedMessageIndex != null">
                        <div class="acton_header mb-4">
                            <div class="left_section w-100">
                                <div class="section_header w-100">
                                    <h2>Message</h2>
                                    <label class="schedule-label" v-if="selectedMessage.has_schedule" @click="viewSchedule = true">View Schedule</label>
                                </div>
                            </div>
                        </div>
                        <div class="setting_wpr">
                            <div>
                                <label :for="`mail-${sequenceUuid}`" class="switch_option capsule_btn">
                                    <h5>E-mail<span>Send E-mail Notification.</span></h5>
                                    <input type="radio" :id="`mail-${sequenceUuid}`" :value="1" v-model="form.send_method" hidden>
                                    <div><span></span></div>
                                </label>
                                <label :for="`sms-${sequenceUuid}`" class="switch_option capsule_btn">
                                    <h5>SMS<span>Send SMS Notification.</span></h5>
                                    <input type="radio" :id="`sms-${sequenceUuid}`" :value="2" v-model="form.send_method" hidden>
                                    <div><span></span></div>
                                </label>
                                <label :for="`both-${sequenceUuid}`" class="switch_option capsule_btn">
                                    <h5>Both<span>Send E-mail &amp; SMS Notification</span></h5>
                                    <input type="radio" :id="`both-${sequenceUuid}`" :value="3" v-model="form.send_method" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="mt-5" v-if="form.send_method == 1 || form.send_method == 3">
                                <email-component v-model="emailMessage" :errors="errors" preview-module="sequence" ref="email-component" :has-reset-default="false" :validate="false" />
                            </div>
                            <div class="mt-5" v-if="form.send_method == 2 || form.send_method == 3">
                                <sms-component v-model="form.sms" media-field-name="sequence_sms_media" preview-module="sequence" ref="sms-component" :has-reset-default="false" :media="form.media" />
                            </div>
                        </div>
                        <label for="sequence_status" class="switch_option capsule_btn border-top mt-3 pt-4">
                            <h5>{{ form.status ? "Published" : "Draft" }}</h5>
                            <input type="checkbox" id="sequence_status" :true-value="1" :false-value="0" v-model="form.status" @change="updateMessageStatus(form)" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button type="button" class="btn save_btn" @click="sendNow = true">Send</button>
                        <button id="save-message-button" class="btn save_btn" v-if="companyUserCan('update', 'sequences')">
                            <i class="fa fa-spin fa-spinner" v-if="sequenceMessageUpdateLoader"></i> {{ sequenceMessageUpdateLoader ? 'Saving' : 'Save' }}
                        </button>
                        <button v-if="companyUserCan('delete', 'sequences')" type="button" class="btn danger_btn" @click="deleteMessage(selectedMessageIndex !== null ? selectedMessageIndex : selectedDelayIndex, selectedMessage.id)">Delete</button>
                    </div>
                </Form>
            </div>
            <div class="tabs_content" v-show="!selectedMessage.id">
                <h6 class="m-5 p-2">Please add a Sequence message</h6>
            </div>
            <div class="preview_content" :class="{ show : preview && selectedMessage.id }">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewMessage" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev" v-if="form.send_method == 3">
                    <li @click="sequencePreviewTab = 'email'" :class="{'active': sequencePreviewTab === 'email'}">E-mail</li>
                    <li @click="sequencePreviewTab = 'sms'" :class="{'active': sequencePreviewTab === 'sms'}">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="(form.send_method === 3 && sequencePreviewTab === 'email') || form.send_method === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(selectedMessage.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="emailMessage.show_email_logo">
                                <img :src="emailMessage.email_logo ? emailMessage.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(emailMessage.email)"></div>
                            <div v-html="generateSignature(emailMessage.is_signature, emailMessage.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="(form.send_method == 3 && sequencePreviewTab === 'sms') || form.send_method == 2">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(form.sms)"></div>
                        </div>
                    </div>
                    <div class="content_area" v-if="!selectedMessage.id">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(selectedMessage.sms)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <analytics-component v-model="analytics" :sequence="sequence" />
        <setting-component v-model="sequenceSettings" :sequence="sequence" />
        <preview-message v-model="previewMessage" :selected-message="selectedMessage" :email-message="emailMessage" />
        <send-now-component v-model="sendNow" :message="selectedMessage" :sequence="sequence" />
        <schedule-component v-model="viewSchedule" :message="selectedMessage" :sequence="sequence" />
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapGetters, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid'
    import { defineAsyncComponent } from 'vue'

    const AnalyticsComponent = defineAsyncComponent(() => import('@/pages/sequence/components/Analytics'))
    const SettingComponent = defineAsyncComponent(() => import('@/pages/sequence/components/Setting'))
    const PreviewMessage = defineAsyncComponent(() => import('@/pages/sequence/components/PreviewMessage'))
    const SendNowComponent = defineAsyncComponent(() => import('@/pages/sequence/components/SendNow'))
    const ScheduleComponent = defineAsyncComponent(() => import('@/pages/sequence/components/Schedule'))

    import Draggable from 'vuedraggable'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Sequence Wizard',

        data () {
            return {
                sendNow: false,
                analytics: false,
                viewSchedule: false,
                sequenceSettings: false,
                sequenceMessages: [],
                selectedDelayIndex: 0,
                selectedMessageIndex: null,
                isMounted: false,
                selectedMessage: {
                    duration: 'days',
                    days: 0,
                },
                preview: true,
                sequencePreviewTab: 'email',
                whiteLabel: {},
                previewMessage: false,
                durations: [
                    { value: 'days', label: 'Day(s)' },
                    { value: 'hours', label: 'Hour(s)' },
                    { value: 'minutes', label: 'Minute(s)' },
                ],
                emailMessage: {
                    defaultEmail: true,
                    subject: '',
                    email: '',
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                sequence_sms_media: [],
                form: {
                    days: 0,
                    duration: 'days',
                    send_method: 1,
                    sms: '',
                    status: 0,
                },
                isResetWizard: false,
                lastSelectedIndex: 0,
                sequenceUuid: 'sequence-'+uuid.v4(),
                messageLoader: false,
                autoUpdate: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Draggable,
            Field,
            Form,
            ErrorMessage,
            AnalyticsComponent,
            SettingComponent,
            PreviewMessage,
            SendNowComponent,
            ScheduleComponent,
        },

        watch: {
            modelValue ()  {
                const vm = this;

                vm.isMounted = false;
                vm.getSequenceMessage(vm.sequence.id);
            },

            sequenceEmails (sequenceEmails) {
                const vm = this;

                vm.sequenceMessages = JSON.parse(JSON.stringify(sequenceEmails));

                if (vm.isResetWizard) {
                    vm.resetWizard();
                } else {
                    if (!vm.selectedMessage.id || !vm.isMounted) {
                        vm.selectedMessage  = vm.sequenceMessages[0] ? vm.sequenceMessages[0] : {};

                        vm.emailMessage = {
                            defaultEmail: true,
                            subject: vm.selectedMessage.subject ? vm.selectedMessage.subject : '',
                            email: vm.selectedMessage.content ? vm.selectedMessage.content : '',
                            show_email_logo: vm.selectedMessage.show_email_logo ? vm.selectedMessage.show_email_logo : 0,
                            email_logo: vm.selectedMessage.email_logo ? vm.selectedMessage.email_logo : '',
                            is_signature: vm.selectedMessage.is_signature ? vm.selectedMessage.is_signature : 0,
                            signature_id: vm.selectedMessage.signature_id ? vm.selectedMessage.signature_id : 0,
                            module_id: vm.selectedMessage.id,
                        };

                        vm.form = {
                            id: vm.selectedMessage.id,
                            days: vm.selectedMessage.days,
                            duration: vm.selectedMessage.duration,
                            send_method: vm.selectedMessage.send_method,
                            sms: vm.selectedMessage.sms,
                            status: vm.selectedMessage.status,
                            media: vm.selectedMessage.media,
                        };

                        vm.sequence_sms_media = vm.selectedMessage.media ? vm.selectedMessage.media : [];

                        vm.resetEmailComponent();
                    }

                    setTimeout(function () {
                        vm.isMounted = true;
                    }, 1000);
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                sequence: state => state.sequenceModule.selectedSequence,
                sequenceEmails: state => state.sequenceModule.sequenceEmails,
                sequenceEmailLoader: state => state.sequenceModule.sequenceEmailLoader,
                sequenceMessageUpdateLoader: state => state.sequenceModule.sequenceMessageUpdateLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getSequenceMessage: 'sequenceModule/getSequenceMessage',
                sortSequenceMessages: 'sequenceModule/sortSequenceMessages',
                updateSequenceMessage: 'sequenceModule/updateSequenceMessage',
                deleteSequenceMessage: 'sequenceModule/deleteSequenceMessage',
                addNewMessage: 'sequenceModule/addNewMessage',
            }),

            closeModal () {
                const vm = this;

                vm.sequencePreviewTab = 'email';
                vm.$emit('update:modelValue', false);
            },

            selectMessage (message, index, type) {
                const vm = this;
                const form = vm.$refs['sequence-form'];

                if (form) {
                    vm.messageLoader = true;

                    form.validate().then((result) => {
                        if (result.valid) {
                            vm.autoUpdate = true;

                            vm.handleSubmit(vm.form, form).then((result) => {
                                if (result) {
                                    setTimeout(function () {
                                        vm.resetSelectedMessage(message, index, type);
                                    }, 10);
                                }
                            });
                        }
                    });
                } else {
                    vm.resetSelectedMessage(message, index, type);
                }
            },

            resetSelectedMessage (message, index, type) {
                const vm = this;

                vm.messageLoader = true;

                if (type == 'delay') {
                    vm.selectedDelayIndex   = index;
                    vm.selectedMessageIndex = null;
                } else {
                    vm.selectedDelayIndex   = null;
                    vm.selectedMessageIndex = null;

                    setTimeout(function () {
                        vm.selectedMessageIndex = index;
                    }, 5);
                }

                vm.sequencePreviewTab = 'email';
                vm.selectedMessage = message;

                vm.emailMessage = {
                    defaultEmail: true,
                    subject: vm.selectedMessage.subject ? vm.selectedMessage.subject : '',
                    email: vm.selectedMessage.content ? vm.selectedMessage.content : '',
                    show_email_logo: vm.selectedMessage.show_email_logo ? vm.selectedMessage.show_email_logo : 0,
                    email_logo: vm.selectedMessage.email_logo ? vm.selectedMessage.email_logo : '',
                    is_signature: vm.selectedMessage.is_signature ? vm.selectedMessage.is_signature : 0,
                    signature_id: vm.selectedMessage.signature_id ? vm.selectedMessage.signature_id : 0,
                    module_id: vm.selectedMessage.id,
                };

                vm.form = {
                    id: vm.selectedMessage.id,
                    days: vm.selectedMessage.days,
                    duration: vm.selectedMessage.duration,
                    send_method: vm.selectedMessage.send_method,
                    sms: vm.selectedMessage.sms,
                    status: vm.selectedMessage.status,
                    media: vm.selectedMessage.media,
                };

                vm.sequence_sms_media = vm.selectedMessage.media ? vm.selectedMessage.media : [];

                setTimeout(function () {
                    vm.messageLoader = false;
                    vm.resetEmailComponent();
                }, 1500);
            },

            updateMessageStatus (message) {
                const vm = this;

                if (vm.isMounted) {
                    vm.isMounted = false;
                    vm.updateSequenceMessage({ id: message.id, status: message.status }).then((result) => {
                        if (result) {
                            if (message.id == vm.form.id) {
                                vm.form.status = message.status;
                            }

                            const index = vm.sequenceMessages.findIndex((m) => m.id === message.id);

                            if (vm.sequenceMessages[index]) {
                                vm.sequenceMessages[index].status = message.status;
                            }

                            Toastr.success(`Sequence message status has been updated to ${ message.status ? 'published' : 'draft' } successfully!`);

                            setTimeout(function () {
                                vm.isMounted = true;
                            }, 100);
                        }
                    });
                }
            },

            resetEmailComponent () {
                const vm = this;

                const component = vm.$refs['email-component'];

                if (component) {
                    component.resetToDefaultWatcher += 1;
                }
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.setFieldError    = setFieldError;
                params.subject          = vm.emailMessage.subject;
                params.content          = vm.emailMessage.email;
                params.show_email_logo  = vm.emailMessage.show_email_logo;
                params.email_logo       = vm.emailMessage.email_logo;
                params.is_signature     = vm.emailMessage.is_signature;
                params.signature_id     = vm.emailMessage.signature_id;
                params.media            = vm.sequence_sms_media;

                return new Promise(function(resolve, reject) {
                    vm.updateSequenceMessage(params).then((result) => {
                        if (result) {
                            if (!vm.autoUpdate) {
                                vm.handleSubscriberValidation();
                            }

                            Toastr.success('Sequence message has been updated successfully!');
                            vm.getSequenceMessage(vm.sequence.id).then((result) => {
                                resolve(true);
                            });
                        } else {
                            resolve(false);
                        }

                        vm.autoUpdate = false;
                    });
                });
            },

            handleMessageShort (e) {
                const vm  = this;
                const ids = [];

                vm.sequenceMessages.forEach((step) => {
                    ids.push(step.id);
                });

                vm.sortSequenceMessages({ ids, sequence_id: vm.sequence.id });
            },

            deleteMessage (index, messageId) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this sequence message.`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteSequenceMessage(messageId).then((result) => {
                                            if (result) {
                                                vm.isResetWizard     = true;
                                                vm.lastSelectedIndex = index;

                                                vm.getSequenceMessage(vm.sequence.id);
                                                Toastr.success('Sequence message has been deleted successfully.');
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleAddMessage (index) {
                const vm = this;
                const params = { id: vm.sequence.id, index };

                vm.addNewMessage(params).then((result) => {
                    if (result) {
                        if (index == 0) {
                            vm.sequenceMessages.push(result);
                            vm.resetSelectedMessage(result, 0, 'content');
                        } else {
                            vm.sequenceMessages.splice(index, 0, result);
                            vm.selectMessage(result, index, 'content');
                        }

                        vm.handleSubscriberValidation();
                    }
                });
            },

            handleSubscriberValidation () {
                const vm = this;

                if (vm.sequence.subscribers > 0) {
                    const options = Helper.swalWarningOptions('', 'This sequence is active and these changes will only be applied to new subscribers.');

                    Swal.fire(options);
                }
            },

            handleEvent (e) {

            },

            toggleSectionbar () {
                const leftSideBar = document.querySelector('.global_setting');

                if (leftSideBar.classList.contains('show_m_details')) {
                    leftSideBar.classList.remove('show_m_details');
                } else {
                    leftSideBar.classList.add('show_m_details');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 1199) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            resetWizard () {
                const vm = this;

                if (vm.sequenceMessages && !vm.sequenceMessages.length) {
                    vm.selectedMessage  = {}
                } else if (vm.sequenceMessages.length > vm.lastSelectedIndex ) {
                    vm.selectedMessage = vm.sequenceMessages[vm.lastSelectedIndex];
                    vm.resetSelectedMessage(vm.selectedMessage, vm.lastSelectedIndex, 'content');
                } else if (vm.sequenceMessages.length == vm.lastSelectedIndex) {
                    vm.selectedMessage = vm.sequenceMessages[vm.lastSelectedIndex - 1];
                    vm.resetSelectedMessage(vm.selectedMessage, vm.lastSelectedIndex - 1, 'content');
                }

                vm.isResetWizard = false;
            }
        },
    }
</script>

<style scoped>
    .sequence_wpr .sequence_details {
        flex: 1;
        padding: 30px 40px;
    }

    .global_setting .tabs_content form>.action_wpr {
        padding: 25px 40px;
        flex-wrap: wrap;
        padding-bottom: 15px;
        gap: 10px;
    }

    .sequence-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .sequence_wpr .left_bar {
        padding: 30px 25px 0 40px;
        border: 1px solid #eee;
        height: 100vh;
        flex: 0 1 420px;
        overflow-y: scroll;
        position: relative;
        z-index: 2;
        background: #fafafb;
        display: flex;
        flex-direction: column;
    }

    .sequence_wpr .left_bar::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    .sequence_wpr .left_bar::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 2px;
    }

    .sequence_card {
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 8px;
    }

    .sequence_card h4 {
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        padding: 10px 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .sequence_card .card_info {
        padding: 15px 20px;
    }

    .sequence_card .card_info p {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #757575;
        margin-bottom: 10px;
    }

    .sequence_card .card_info .action {
        display: flex;
        justify-content: space-between;
    }

    .sequence_list {
        padding-top: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto 20px auto;
    }

    .sequence_list>li {
        border-radius: 8px;
        padding-bottom: 70px;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .sequence_list>li:not(:first-child) {
        padding-top: 30px;
    }

    .sequence_list>li:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        z-index: -1;
    }

    .sequence_list .sequence_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sequence_list .sequence_item li:not(:last-child) {
        padding-bottom: 40px;
    }

    .sequence_wpr .step_type {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 1;
    }

    .sequence_wpr .add_new {
        width: 40px;
        height: 40px;
        font-size: 15px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #5a5a5a;
        cursor: pointer;
    }

    .sequence_list li .card_details {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 500;
    }

    .sequence_list li .card_details p {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 3px 0;
    }

    .sequence_list li .step_info {
        border: 1px solid #e9e9e9;
        background: #fff;
        position: relative;
        overflow: hidden;
    }

    .sequence_list li .step_info.active {
        border: 2px solid #bad7ff;
    }

    .sequence_list li .step_info.draft>*:not(button) {
        opacity: 0.5;
    }

    .sequence_list li .remove_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        right: -25px;
        top: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .sequence_list li .remove_btn i {
        color: #5a5a5a !important;
    }

    .sequence_list li .step_info:hover .remove_btn {
        right: 10px;
    }

    .sequence_list li .step_info.timer {
        padding: 10px 20px;
        border-radius: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 230px;
    }

    .sequence_list li .step_info.mail {
        border-radius: 8px;
        min-width: 300px;
    }

    .sequence_list li .step_info.timer i {
        color: #b9b9b9;
    }

    .sequence_list li .step_info.timer h4 {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .sequence_list li .step_info.mail h4 {
        text-align: center;
        padding: 12px 20px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        position: relative;
    }

    .sequence_list li .step_info.mail h4:hover {
        cursor: move;
    }

    .sequence_list li .step_info.mail h4 label {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: #9b9b9b;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        left: 20px;
        top: 13px;
        transition: all 0.3s ease-in-out;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box,
    .sequence_list li .step_info.mail .msg_cont .sms_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 20px 15px 20px;
        border-top: 1px solid #e9e9e9;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .status_area,
    .sequence_list li .step_info.mail .msg_cont .sms_box .status_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .score_circle {
        width: 30px;
        height: 30px;
        padding: 2px;
    }

    .sequence_list li .step_info.mail .msg_cont .mail_box .score_circle .inner_circle .score {
        font-size: 15px;
        line-height: 18px;
    }

    .sequence_list li .step_info.mail .msg_cont .msg_desc {
        border: 1px dashed #c9c9c9;
        padding: 10px;
        text-align: center;
        margin: 5px 0 10px;
        min-width: 200px;
    }

    .sequence_list li .step_info.mail .msg_cont .msg_desc p {
        max-height: 36px;
        font-size: 13px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .add_types {
        width: 200px;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: none;
    }

    .step_type.active .add_types {
        display: block;
    }

    .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .add_types h3 {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        font-weight: 500;
        padding: 12px 15px;
    }

    .add_types ul {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }

    .add_types ul li {
        width: 100%;
        padding: 10px;
    }

    .add_types ul li i {
        font-size: 25px;
        color: #c4c4c4;
        margin-bottom: 10px;
    }

    .add_types ul li h4 {
        font-weight: 500;
    }

    .sequence_wpr .left_bar .primary_btn {
        font-size: 15px;
        line-height: 18px;
        height: 40px;
    }

    .sequence_wpr .left_bar .extra_info {
        padding: 15px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 0;
    }

    .sequence_wpr .tabs_content .extra_info {
        padding: 20px;
        font-size: 12px;
        line-height: 19px;
        font-weight: 500;
        color: #121525;
        display: block;
        margin-bottom: 15px;
        background: #e4e4e4;
        border: 1px solid #d9d9d9;
    }

    .sequence_wpr .tabs_content .extra_info a span {
        color: #2f7eed;
    }

    .sequence_details .acton_header .extra_info {
        padding: 15px;
        font-size: 13px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
    }

    .sequence_details .acton_header .extra_info span {
        color: #2f7eed;
        font-weight: 500;
        padding: 0 5px;
        cursor: pointer;
    }

    .multiselect.day_input {
        min-height: 25px;
        border: 1px solid #a0c7ff;
        padding: 0 10px;
        margin: 0 5px;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-weight: 500;
        text-align: center;
        width: 80px;
    }

    .sqr_input {
        height: 25px;
        border: 1px solid #a0c7ff;
        padding: 0 10px;
        margin: 0 5px;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-weight: 500;
        text-align: center;
        width: 30px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .preview_content {
        width: 450px;
        padding: 0 40px;
        position: relative;
        margin-right: -450px;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 4;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 130px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: -30px -15px;
        transform: scale(0.8);
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p,
    .cell .msg_preview p :deep(p),
    .cell .sms_preview p :deep(p) {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .cell .msgFooter p {
        font-size: 11px;
        line-height: 17px;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .action_wpr .btn {
        margin: 0;
    }

    .schedule-label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        color: #2F7FED;
    }
    .m_footer{
        display: none;
        position: sticky;
        z-index: 1;
        bottom: 0;
        margin: auto -35px 0 -35px;
        /* padding: 12px 30px; */
        background: #fff;
        border-top: 1px solid #e9e9e9;
    }
    .m_footer .save_btn{
        padding: 0 20px;
        height: 35px;
        background: #fff;
        color: #2f7eed;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        /* border-radius: 3px; */
        flex-grow: 1;
    }
    .tabs_content .close_btn {
        display: none;
        position: fixed;
        right: 5px;
        left: auto;
        top: 100vh !important;
        transition: all 0.3s ease-in-out;
    }
    .global_setting.show_m_details .tabs_content .close_btn{
        top: 90px !important;
    }
    @media(max-width: 1399px){
        .sequence_wpr .left_bar {
            flex: 0 1 370px;
        }
        .global_setting .action_wpr .btn {
            height: 30px;
            line-height: 30px;
            font-size: 11px;
            padding: 0 15px;
            min-width: auto;
        }
    }
    @media(max-width: 1199px){
        .global_setting .preview_content{
            width: 300px !important;
            position: absolute;
            right: -300px;
            padding: 0 30px;
        }
        .global_setting .preview_content .cell {
            transform: scale(0.6);
            margin: -35% !important;
        }
        .global_setting .preview_content.show{
            right: 0;
        }
    }
    @media(max-width: 991px){
        .preview_content .cell {
            transform: scale(0.6);
            margin: -40% !important;
        }
    }
    @media(max-width: 767px){
        .sequence_wpr .left_bar{
            flex: 0 0 100%;
            padding: 30px 35px 0 35px;
        }
        .sequence_wpr .sequence_details{
            padding: 30px 35px;
        }
        .global_setting .tabs_content form>.action_wpr{
            padding: 20px 35px;
            gap: 5px;
        }
        .sequence_wpr .tabs_content{
            left: 0;
            position: fixed;
            height: calc(100vh - 85px);
            top: 100vh;
            z-index: 6;
            width: 100%;
            min-width: auto;
            transition: all .3s ease-in-out;
            border-radius: 20px 20px 0 0;
        }
        .global_setting.show_m_details .tabs_content {
            top: 85px;
            box-shadow: 0 -2px 30px rgb(0, 0, 0, 0.15);
        }
        .m_footer{
            display: flex;
        }
        .tabs_content .close_btn{
            display: block;
        }
    }

    .tabs_content h6 {
        font-size: 20px;
        font-weight: 400;
        margin-top: 40px;
        margin-left: 20px;
    }

    .message_loader_area {
        height: 100vh;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
</style>
